.h2{
  font-size: var(--fontSize6);
  text-align: center;
  @media (max-width: 768px){
    font-size: var(--fontSize5);
  }
  @media (max-width: 500px){
    font-size: var(--fontSize4);
  }
}

/*floating sections (with the colors and images)*/
section.floater{
  $collapse: 800px;
  position: relative;

  min-height: calc(100vh - var(--navHeight));

  >div{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h2{
      z-index: 2;

      width: auto;
      overflow: visible;
    }

    .floatingImage{
      //this nth stuff determines whether to show an alt image for small screens
      &:nth-of-type(1){
        display: flex;
        &:not(:last-child){
          @media (max-width: $collapse){
            display: none;
          }
        }
      }
      &:nth-of-type(2){
        display: none;
        @media (max-width: $collapse){
          display: flex;
        }
      }
      @media (max-width: 800px){
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}


/*fold*/
section.fold{
  position: relative;
  z-index: 2;

  box-sizing: border-box;

  height: calc(100vh - var(--navHeight));

  background: var(--blue);

  .sectionInner{
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: var(--sectionInnerMaxWidth);
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  h1{
    margin: 0;

    color: var(--white);
    font-weight: 100;
    font-size: min(10vw, 10vh);
    @media (min-width: 768px) and (min-height: 768px){
      font-size: var(--fontSize7);
    }
    span{
      font-weight: 800;
    }
  }


  .svgWrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    overflow: visible;
    svg{
      position: relative;
      top: 0;
      left: 40%;

      width: auto;
      height: 100vh;
      overflow: visible;
      @media (max-width: 1000px){
        left: 30%;

        width: 130%;
        height: auto;
        overflow: visible;
      }
    }
  }
}


section.question{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--navHeight));

  background: var(--blue);

  h2{
    text-align: left;
  }
}

section.yellow{
  background: var(--yellow);
}

section.red{
  background: var(--red);
}

section.pink{
  background: var(--pink);
}
section.green{
  background: var(--green);
}
section.cyan{
  background: var(--cyan);
}

section.answer{
  height: calc(100vh - var(--navHeight));

  background-color: var(--blue);

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h2{
      margin-bottom: 15vh;

      color: var(--purpleLighter2);
    }

    .revealedLogo{
      width: 70%;
    }
  }
}


section.partnerSection{
  background: var(--purple);

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
      margin-bottom: var(--spacing9);
    }

    .partners{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: var(--spacing9);
      justify-items: center;
      @media (max-width: 850px){
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing8);
      }
      @media (max-width: 550px){
        grid-template-columns: 1fr;
      }
    }
  }
}

