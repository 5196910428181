/*text
border
background

Ghost: text === border
Raised: Border === background*/


.deviateButton{
  --text: var(--white);
  --border: var(--color);
  --background: var(--color);

  --textHover: var(--color);
  --borderHover: var(--white);
  --backgroundHover: var(--white);

  position: relative;


  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  padding: var(--spacing5) var(--spacing7);
  overflow: hidden;

  background: transparent;
  background-color: var(--background);
  border: none;
  border-radius: var(--curve);
  outline: none;

  transition: background-color 0.1s ease-in-out;

  user-select: none;


  &.grayscale{
    --text: var(--black);
    --border: var(--white);
    --background: var(--white);

    --textHover: var(--black);
    --borderHover: var(--white);
    --backgroundHover: var(--white);
  }

  &.ghost{
    --text: var(--color);
    --border: var(--color);
    --background: transparent;

    --textHover: var(--white);
    --borderHover: var(--color);
    --backgroundHover: var(--color);


    &.grayscale{
      --text: var(--white);
      --border: var(--white);
      --background: transparent;

      --textHover: var(--black);
      --borderHover: var(--white);
      --backgroundHover: var(--white);
    }
  }

  &.light{
    --text: var(--white);
    --border: var(--color);
    --background: var(--color);

    --textHover: var(--color);
    --borderHover: var(--white);
    --backgroundHover: var(--white);


    &.grayscale{
      --text: var(--black);
      --border: var(--white);
      --background: var(--white);

      --textHover: var(--black);
      --borderHover: var(--white);
      --backgroundHover: var(--white);
    }

    &.ghost{
      --text: var(--white);
      --border: var(--white);
      --background: transparent;

      --textHover: var(--color);
      --borderHover: var(--white);
      --backgroundHover: var(--white);


      &.grayscale{
        --text: var(--white);
        --border: var(--white);
        --background: transparent;

        --textHover: var(--black);
        --borderHover: var(--white);
        --backgroundHover: var(--white);
      }
    }
  }

  span{
    position: relative;
    z-index: 1;

    color: var(--text);
    font-weight: 700;
    font-size: var(--fontSize2);
    line-height: 1em;
    letter-spacing: 2px;
    white-space: nowrap;
    text-transform: uppercase;

    transition: color var(--speed1) ease-in-out;
  }
  svg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;

    height: 100%;
    path{
      transition: stroke var(--speed1) ease-in-out;

      stroke: var(--border);
    }
  }

  &:hover,
  &.ghost:hover,
  &.ghost.grayscale:hover{
    --text: var(--textHover);
    --border: var(--borderHover);
    --background: var(--backgroundHover);
  }

  &.large{
    padding: var(--spacing6) var(--spacing7);
    span{
      font-size: var(--fontSize3);
    }
  }
}
