/*revealer animation*/
.revealing{
  position: relative;

  width: max-content;
  margin-left: -0.5em;

  padding: 0 0.5em;

  overflow: hidden;

  border: 0 solid transparent;
  border-radius: 9999px;

  .revealingHider{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 9999px;

    pointer-events: none;
  }
  .revealingText{
    opacity: 0;
  }
}

.revealing .revealing.animated .revealing.animated .revealingHider{
  animation: hider 1s ease-in-out;
}
@keyframes text{
  0%{
    opacity: 0;
  }
  55%{
    opacity: 0;
  }
  56%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}
@keyframes hider{
  0%{
    transform: translateX(120%);
    transform-origin: right;
    opacity: 1;
  }
  45%{
    transform: translateX(0);
    opacity: 1;
  }
  55%{
    transform: translateX(0);
    opacity: 1;
  }
  100%{
    transform: translateX(-120%);
    transform-origin: left;
    opacity: 1;
  }
}
