/*navbar*/
.dlNav{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000; //for the wp blog

  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;

  width: 100vw;
  height: var(--navHeight);
  padding: 0 var(--spacing6);

  background: var(--blue);
  -webkit-backface-visibility: hidden;


  .main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .logo{
      display: flex;
      align-items: center;
      max-width: 273px;
      height: 35px;

      svg{
        width: 100%;
        height: auto;
      }
    }

    .right{
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .buttonWrapper{
        display: flex;
        align-items: center;
        @media (max-width: 800px){
          display: none;
        }

        .link{
          display: flex;
          align-items: center;

          color: var(--color);
          text-decoration: none;

          transition: color var(--speed1) ease-in-out;
          @media (max-width: 1200px){
            display: none;
          }

          svg{
            margin-right: var(--spacing5);

            path{
              transition: fill var(--speed1) ease-in-out;

              fill: var(--color);
            }
          }

          &:hover{
            color: var(--white);

            svg{
              path{
                fill: var(--white);
              }
            }
          }
        }

        >*:not(:last-child){
          margin-right: var(--spacing6);
        }
      }
    }
  }

  .hamburger{
    display: flex;
    width: 3rem;
    margin: 0 0 0 var(--spacing8);

    .close{
      opacity: 0;
    }

    svg{
      overflow: visible;
      path{
        transition: fill var(--speed1) ease-in-out;

        fill: var(--color);
      }
    }

    &:hover{
      svg path{
        fill: var(--white);
      }
    }
  }

  .fullscreenItem{
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-start;
    height: 150vh;
    margin-right: -1px;
    padding: 60vh var(--spacing6) var(--spacing6);

    background: var(--blue);

    border-left: 1px solid transparent;
    --color: var(--purple);


    .itemLine{
      width: 20px;
      height: 10px;
      margin-bottom: var(--spacing6);

      background-color: var(--color);

      border-radius: 5px;

      transition: all 0.3s ease-in-out;
    }

    .itemTitle{
      margin-bottom: var(--spacing7);

      color: var(--color);

      transition: all 0.3s ease-in-out;
    }

    &.purple{--color: var(--purple);}
    &.cyan{--color: var(--cyan);}
    &.green{--color: var(--green);}
    &.red{--color: var(--red);}
    &.pink{--color: var(--pink);}
    &.yellow{--color: var(--yellow);}

    &:hover{
      -webkit-tap-highlight-color: transparent;
      --color: var(--white);

      &.purple{background: var(--purple);}
      &.yellow{background: var(--yellow);}
      &.green{background: var(--green);}
      &.cyan{background: var(--cyan);}
      &.red{background: var(--red);}
      &.pink{background: var(--pink);}

      .itemLine{
        @media (min-width: 950px){
          transform: translateY(-200px);
        }
      }
      .itemTitle{
        @media (min-width: 950px){
          transform: translateY(-200px);
        }
      }
      .itemSubtitle{
        @media (min-width: 950px){
          transform: translateY(-200px);
          opacity: 1;
        }
      }
    }
  }

  .fullscreenNav{
    position: fixed;
    top: var(--navHeight);
    left: 0;

    display: flex;

    width: 100vw;
    height: calc(100% - var(--navHeight));

    pointer-events: none;
    .fullscreenItem{
      border-left: 1px solid var(--separatorColor);

      transition: border-right 0.3s ease-in-out forwards;
    }
  }

  .itemSubtitle{
    color: var(--white);

    transform: translateY(200px);
    opacity: 0;

    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 950px){
  nav.dlNav{
    .fullscreenNav{
      flex-direction: column-reverse;

      .fullscreenItem{
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        box-sizing: border-box;
        height: initial;
        margin-top: -1px;
        margin-right: 0;
        padding: 0 var(--spacing7);

        border-top: 1px solid var(--separatorColor);
        border-left: none;
        box-shadow: 0 1px var(--separatorColor); //fixes fractional scaling bug in some browsers
      }
      .itemTitle{
        margin: 0;

        font-size: var(--fontSize3);
      }
      .itemLine,
      .itemSubtitle{
        display: none;
      }
    }
  }
}
