/*bouncy arrows*/
.arrow{
  display: block;
  margin-top: var(--spacing8);

  animation: bounce 1s infinite alternate ease-in-out;

  img{
    width: 3rem;
  }
  @media (min-width: 768px){
    .arrow{
      margin-top: var(--spacing9);
    }
    .arrow img{
      width: auto;
    }
  }
  @keyframes bounce{
    from{
      transform: translateY(-15px);
    }
    to{
      transform: translateY(15px);
    }
  }
}
