footer.footer{
  $centeredCollapse: 750px;
  background-color: var(--blue);

  .sectionInner{
    display: flex;
    flex-direction: column;

    .headline{
      display: flex;
      flex-direction: column;
      margin-bottom: var(--spacing6);

      color: var(--color);
      font-weight: 700;
      font-size: var(--fontSize3);
      white-space: nowrap;
      @media (max-width: $centeredCollapse){
        align-items: center;

        text-align: center;
      }

      &::after{
        width: 4.25rem;
        height: 0.4rem;
        margin-top: var(--spacing5);

        background-color: var(--color);
        border-radius: 0.75rem;

        content: "";
        @media (max-width: 400px){
          width: 3.25rem;
          height: 0.3rem;
          margin-top: var(--spacing2);
        }
      }
    }


    .logoSeparator{
      display: flex;
      align-items: center;
      margin: var(--spacing8) 0 var(--spacing5) 0;
      @media (max-width: 500px){
        margin: var(--spacing7) 0 var(--spacing5) 0;
      }

      &::before,
      &::after{
        flex-grow: 1;
        height: 1px;

        background-color: var(--separatorColor);

        content: "";
      }

      svg{
        width: auto;
        height: 2.5rem;
        margin: 0 var(--spacing5);
        @media (max-width: 600px){
          flex-direction: column;
          height: 1.5rem;
        }
        @media (max-width: 500px){
          flex-direction: column;
          height: 1rem;
        }

        .text{
          fill: var(--color);
        }
      }
    }

    .top{
      display: flex;
      gap: var(--spacing8);
      justify-content: space-between;
      @media (max-width: 1150px){
        display: grid;
        grid-template-columns: repeat(2, min-content);
        align-self: center;
      }
      @media (max-width: 750px){
        grid-template-columns: repeat(1, min-content);
      }
      @media (max-width: $centeredCollapse){
        align-items: center;

        text-align: center;
      }

      .contactInfo{
        display: flex;
        flex-direction: column;
        .locations{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media (max-width: $centeredCollapse){
            align-items: center;
          }

          .location{
            display: grid;
            grid-template-columns: min-content min-content;
            justify-items: flex-start;

            color: var(--color);
            white-space: nowrap;
            column-gap: var(--spacing3);
            row-gap: var(--spacing3);
            @media (max-width: 400px){
              font-size: var(--fontSize1);
            }


            &:not(:first-child){
              margin-top: var(--spacing6);
            }

            .icon{
              display: flex;
              grid-row: span 2;
              align-items: center;
              justify-content: center;
              margin-right: var(--spacing4);
              svg{
                path{
                  fill: var(--color);
                }
              }
            }

            .key{
              font-weight: 700;
            }
          }
        }
      }

      .socialLinks{
        display: flex;
        gap: var(--spacing5);
        margin-top: var(--spacing6);
        @media (max-width: $centeredCollapse){
          justify-content: center;
        }


        a{
          width: 2rem;
          height: 2rem;

          transition: transform 0.1s ease-in-out;
          svg{
            width: 100%;
            height: 100%;
            path{
              transition: fill 0.1s ease-in-out;

              fill: var(--color);
            }
          }

          &:hover{
            transform: scale(1.1, 1.1);
            svg path{
              fill: var(--white);
            }
          }
        }
      }

      .navLinks{
        display: flex;
        flex-direction: column;

        font-size: var(--fontSize2);

        .headline{
          margin-bottom: calc(var(--spacing6) - var(--spacing6)/2); //adjust for the padding around the links
        }
        a{
          padding: calc(var(--spacing6)/2) 0;

          color: var(--color);
          white-space: nowrap;

          &:hover{
            color: var(--white);
          }
          @media (max-width: 850px){
            flex-basis: 20%;
          }
        }
      }
    }


    .miscellaneous{
      display: flex;

      flex-direction: column;
      align-items: center;

      color: var(--color);
      font-size: var(--fontSize1);
      font-style: italic;

      opacity: 0.75;

      >*:not(:first-child){
        margin-top: var(--spacing5);
        @media (max-width: 500px){
          margin-top: var(--spacing4);
        }
      }
    }
  }
}
