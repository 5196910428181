.floatingImage{
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;

  .floatingImageInner{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .floatingImagePicture{
      display: flex;
      width: 100%;
      max-height: 85%;
    }

    picture{
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;


      img{
        max-width: 85%;
        max-height: 85%;
        object-fit: contain;

        border-radius: var(--curve);
      }
    }


    .floatingElement{
      animation: floating-element 1.7s infinite alternate ease-in-out;
    }
    @keyframes floating-element{
      from{
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      to{
        -webkit-transform: translateY(min(2vw, 4rem));
        transform: translateY(min(2vw, 4rem));
      }
    }

    .floatingShadow{
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 15%;
      margin: 0 auto;

      animation: floating-shadow 1.7s infinite alternate ease-in-out;

      img{
        max-width: 75%;
      }
    }
    @keyframes floating-shadow{
      from{
        transform: scale(0.7, 1);
      }
      to{
        transform: scale(1.2, 1);
      }
    }
  }
}

